/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $(window).load(function() {
          $('#status').fadeOut(); // will first fade out the loading animation 
          $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
        });        
        
        // MENU ON MOBILE
        $(document).on('click','.navbar-collapse.in',function(e) {
            if( $(e.target).is('a') && $(e.target).attr('class') !== 'dropdown-toggle' ) {
                $(this).collapse('hide');
            }
        });         
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        // SCROLL ON THE SAME PAGE
        
        $('a[href*="#"]:not([href="#"])').click( function(event) {
                   event.preventDefault();
                 var anchor = 0;
                 if($(this.hash).offset().top > $(document).height()-$(window).height()){
                      anchor=$(document).height()-$(window).height();
                 }else{
                  anchor = $(this.hash).offset().top - 119 ;
                   }
                 $('html,body').stop().animate({scrollTop:anchor}, 1000,'swing');
             });
         $('.nav li a').click(function() {
            $(this).parent().find('a').removeClass('current');
            $(this).addClass('current');
        });                       
        
        $(window).scroll(function() {
            $('.section').each(function() {
                if($(window).scrollTop() >= $(this).offset().top - 121) {
                    var id = $(this).attr('id');
                    $('.nav li a').removeClass('current');
                    $('.nav li a[href=#'+ id +']').addClass('current');
                }
            });
        });         
        
        // MATCH HEIGHT
        $(function() {
            $('.onas-logo').matchHeight({
                target: $('.onas-match-height')
            });
        });               
        // POPUP
        $(".modal").iziModal({        
          padding: 50,
          overlayColor: 'rgba(32, 69, 124, 0.6)',
          overlayClose: true,
          zindex: 1100,
          group: 'Oferta',
          loop: true,
          navigateCaption: false,
          navigateArrows: 'closeScreenEdge'
        });        
//        $(document).on('click', '.trigger-1', function (event) {
//            event.preventDefault();
//            $('#modal-1').iziModal('open');
//        });  
//        $("#modal-1").iziModal({
//          padding: 50,
//          overlayColor: 'rgba(32, 69, 124, 0.6)',
//        });
//        
//        $(document).on('click', '.trigger-2', function (event) {
//            event.preventDefault();
//            $('#modal-2').iziModal('open');
//        });  
//        $("#modal-2").iziModal({
//          padding: 50,
//          overlayColor: 'rgba(32, 69, 124, 0.6)',
//        });
//        
//        $(document).on('click', '.trigger-3', function (event) {
//            event.preventDefault();
//            $('#modal-3').iziModal('open');
//        });  
//        $("#modal-3").iziModal({
//          padding: 50,
//          overlayColor: 'rgba(32, 69, 124, 0.6)',
//        });        
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
